@import 'styles/mixins.scss';

.cardContainer {
    height: 140px;
    width: 33.3%;
    max-width: 288px;
    border-radius: 8px;
    background-color: $green;
    padding: 16px;
    overflow: hidden;

    .cardSubTitle {
        color: whiteColorWithOpacity(0.87);
    }
}
