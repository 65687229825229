@import 'styles/mixins.scss';

.tagContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    border: 1px solid grayColorWithOpacity(0.12);
    border-radius: 18px;
    background-color: $white;
    margin: 2px;
}
