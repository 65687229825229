@import 'styles/mixins.scss';

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 165px;
    padding: 12px;
    border-radius: 8px;
    border: unset;
    outline: unset;
    margin-right: 8px;
    @include linearGradient(
        $angle: 135deg,
        $middlePoint: 52.28%,
    );
}
