@import 'styles/mixins.scss';

.dialogBackdrop {
    background-color: blackColorWithOpacity(0.38) !important;
}

.dialogPaper {
    border-radius: 12px !important;
    padding: 64px 80px;
    height: 546px;
    width: 608px;
    max-width: none !important;
    background-color: $white;

    @media screen and (max-height: 768px) {
        padding: 44px 80px;
    }
}

.dialogTitle {
    display: flex;
    justify-content: center;
    padding: 0px 0px 8px 0px !important;
}

.dialogContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px !important;
    overflow-y: hidden !important;
}

.dialogActions {
    padding: 0px !important;
}

.verifyEmailText {
    text-align: center;
    max-width: 280px;
}
