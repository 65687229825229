@import 'styles/mixins.scss';

.cardContainer {
    height: 140px;
    width: 33.3%;
    max-width: 288px;
    border: 1px solid grayColorWithOpacity(0.12);
    border-radius: 8px;
    background-color: $white;
    padding: 16px;
    overflow: hidden;

    .cardSubTitle {
        color: lightBlackColorWithOpacity(0.6);
    }

    .instructorCode {
        color: lightBlackColorWithOpacity(0.87);
    }
}
