@import 'styles/colors.scss';

.studentAvatar {
    object-fit: cover;
    width: 128px;
    height: 128px;
    border-radius: 50%;
}

.studentExamInfo {
    background-color: $green;
    padding: 16px;
    border-radius: 8px;

    .iconSize {
        min-width: 24px;
        height: 24px;
    }

    .iconWrapper {
        @extend .iconSize;
        border: 2px solid $white;
        border-radius: 50%;
    }
}
