@import 'styles/mixins.scss';

.text {
    &.bold {
        font-weight: bold;
    }

    &.largeText {
        font-size: 26px;
        font-family: 'NunitoSansBold';
        letter-spacing: 0.46px;
        line-height: 36px;
    }

    &.mediumText {
        font-size: 16px;
        font-family: 'NunitoSansRegular';
        letter-spacing: 0.29px;
        line-height: 22px;
    }

    &.normalText {
        font-size: 14px;
        font-family: 'NunitoSansRegular';
        letter-spacing: 0.25px;
        line-height: 19px;
    }

    &.smallText {
        font-size: 12px;
        font-family: 'NunitoSansRegular';
        letter-spacing: 0.21px;
        line-height: 16px;
    }

    &.blackColor {
        color: $lightBlack;
    }

    &.grayColor {
        color: grayColorWithOpacity(0.5);
    }

    &.whiteColor {
        color: $white;
    }

    &.redColor {
        color: $red;
    }

    &.greenColor {
        color: $green;
    }

    &.violetColor {
        color: $violet;
    }

    &.orangeColor {
        color: $orange;
    }

    &.blueColor {
        color: $lightBlue;
    }

    &.truncate {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
