@import 'styles/mixins.scss';

.simulatorProgressCardContainer {
    height: 160px;
    width: 33.3%;
    max-width: 368px;
    border-radius: 8px;
    border: 1px solid grayColorWithOpacity(0.12);
    background-color: $white;
    padding: 15px;
    overflow: hidden;
    margin-right: 32px;

    .chevronRightIcon {
        min-width: 24px;
        max-width: 24px;
    }
}
