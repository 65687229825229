@import 'styles/mixins.scss';

.formInputContainer {
    width: 100%;
    min-height: 43px;
    max-height: 43px;
    padding: 12px 16px;
    background-color: $inputGray;
    border-radius: 8px;

    &.error {
        border: 1px solid $red;
    }

    .formInput {
        width: 100%;
        border: none;
        outline: none;
        color: $lightBlack;
        background-color: $inputGray;

        &::placeholder {
            color: lightBlackColorWithOpacity(0.38);
        }

        &:-webkit-autofill {
            &,
            &:hover,
            &:focus,
            &:active {
                box-shadow: 0 0 0px 1000px $inputGray inset;
            }

            -webkit-text-fill-color: $lightBlack;
        }

        /* Hide arrows for number input type on Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Hide arrows for number input type on Firefox */
        &[type=number] {
            -moz-appearance: textfield;
        }

        &.defaultCursor {
            cursor: default;
        }

        &.textCursor {
            cursor: text;
        }
    }
}
