// Default colors
$black: #000000;
$white: #FFFFFF;

// Colors from design
$lightBlue: #73D1F7;
$blue: #668FE7;
$violet: #7A50EE;
$gray: #767680;
$lightGray: #F4F5F7;
$inputGray: #EEEEEF;
$lightBlack: #1A1A1A;
$red: #F53131;
$lightRed: #EF4D5E;
$green: #00CB85;
$orange: #F49525;
