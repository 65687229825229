@import 'styles/mixins.scss';

.progressContainer {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: lightBlackColorWithOpacity(0.08);
    z-index: 100;
    overflow: hidden;

    @mixin progress {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        min-width: 0%;
        max-width: 100%;
        border-radius: inherit;
    }

    & .totalProgress {
        @include progress;
        background-color: $lightRed;
        z-index: 200;
    }

    & .correctProgress {
        @include progress;
        background-color: $green;
        z-index: 300;
    }
}
