@import 'styles/colors.scss';

.overallProgressCardContainer {
    position: relative;
    height: 160px;
    width: 33.3%;
    max-width: 368px;
    border-radius: 8px;
    background-color: $violet;
    padding: 16px;
    overflow: hidden;
    margin-right: 32px;

    .iconWrapper {
        border: 2px solid $white;
        border-radius: 50%;
        min-width: 20px;
        max-width: 20px;
        min-height: 20px;
        max-height: 20px;
    }

    .chevronRightIcon {
        min-width: 24px;
        max-width: 24px;
    }

    .almostThereText {
        position: absolute;
        left: 16px;
        right: 16px;
        bottom: 12px;
    }
}
