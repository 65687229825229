@import 'styles/mixins.scss';

.container {
    width: 100%;
    height: 64px;
    border: 1px solid grayColorWithOpacity(0.12);
    border-radius: 8px;
    background-color: $white;
    padding: 12px 24px;

    .studentIconContainer {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: orangeColorWithOpacity(0.08);
    }

    .subTitle {
        color: lightBlackColorWithOpacity(0.6);
    }
}
