@import 'styles/colors.scss';

@mixin linearGradient(
    $angle,
    $startingPoint: 0%,
    $middlePoint,
    $endingPoint: 100%,
) {
    background: linear-gradient(
        $angle,
        $lightBlue $startingPoint,
        $blue $middlePoint,
        $violet $endingPoint,
    );
}

@function whiteColorWithOpacity($opacity: 1) {
    @return rgba(255, 255, 255, $opacity);
}

@function blackColorWithOpacity($opacity: 1) {
    @return rgba(0, 0, 0, $opacity);
}

@function grayColorWithOpacity($opacity: 1) {
    @return rgba(118, 118, 128, $opacity);
}

@function greenColorWithOpacity($opacity: 1) {
    @return rgba(0, 203, 133, $opacity);
}

@function orangeColorWithOpacity($opacity: 1) {
    @return rgba(244, 149, 37, $opacity);
}

@function lightBlackColorWithOpacity($opacity: 1) {
    @return rgba(26, 26, 26, $opacity);
}

@function lightRedColorWithOpacity($opacity: 1) {
    @return rgba(239, 77, 94, $opacity);
}

@function lightVioletColorWithOpacity($opacity: 1) {
    @return rgba(122, 80, 238, $opacity);
}
