@import 'styles/mixins.scss';

.navigationBarContainer {
    height: 100%;
    width: 255px;
    display: flex;
    flex-direction: column;
    background-color: $white;
    box-shadow: 0 10px 50px 0 blackColorWithOpacity(0.08);
    border-right: 1px solid $lightGray;

    .navBarLogoContainer {
        padding: 24px;
        margin-bottom: 8px;
    }

    .buttonLinkContainer {
        display: flex;
        align-items: center;
        height: 48px;
        // gap: 12px;
        padding: 12px 24px;
    }

    .loginIconWrapper {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid $green;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;
    }
    
    .logoutIcon {
        margin-right: 12px;
    }
}
