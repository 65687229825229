@import 'styles/mixins.scss';

.tabContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    background-color: $white;
    border: 1px solid lightVioletColorWithOpacity(0.08);

    &.joined {
        margin-right: 0px;

        &:first-child {
            border-radius: 19px 0px 0px 19px;
        }

        &:last-child {
            border-radius: 0px 19px 19px 0px;
        }
    }

    &.separated {
        border-radius: 19px;

        &:not(:last-child) {
            margin-right: 12px;
        }
    }
}

.activeTab {
    background-color: lightVioletColorWithOpacity(0.08);
}

.disabledTab {
    opacity: 0.5;
}
