@use "sass:math";
@import 'styles/mixins.scss';

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background-color: lightBlackColorWithOpacity(0.38);
}

.wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle {
    position: relative;
    width: 80px;
    height: 80px;
}

.circle .circle-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.circle .circle-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: $white;
    border-radius: 100%;
    -webkit-animation: circleBounceDelay 1.2s infinite ease-in-out both;
    animation: circleBounceDelay 1.2s infinite ease-in-out both;
}

@for $i from 2 through 12 {
    $degCalc: #{($i - 1) * 30};

    .circle .circle#{$i} {
        -webkit-transform: rotate(#{$degCalc}deg);
        -ms-transform: rotate(#{$degCalc}deg);
        transform: rotate(#{$degCalc}deg);
    }
}

@for $i from 2 through 12 {
    $delay: #{1.1 - (math.div($i - 2, 10))};

    .circle .circle#{$i}:before {
        -webkit-animation-delay: -#{$delay}s;
        animation-delay: -#{$delay}s;
    }
}

@-webkit-keyframes circleBounceDelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes circleBounceDelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
