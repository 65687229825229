.formWrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;

    .chevronLeftIcon {
        min-height: 24px;
        max-height: 24px;
        min-width: 24px;
        max-width: 24px;
        cursor: pointer;
    }

    form {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .errorText {
        text-align: center;
    }
}
