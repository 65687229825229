@import 'styles/mixins.scss';

.root {
    z-index: 4000 !important;
}

.backdrop {
    background-color: blackColorWithOpacity(0.38) !important;
}

.paper {
    border-radius: 12px !important;
    padding: 48px 80px;
    width: 600px;
}

.title {
    text-align: center;
    max-width: 300px;
}

.content {
    padding: 0px !important;
}

.actions {
    padding: 0px !important;
}

.confirmationDialogHeaderIcon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    &.error {
        background-color: lightRedColorWithOpacity(0.08);
    }
    &.success {
        background-color: greenColorWithOpacity(0.08);
    }
}

.confirmationDialogContentText {
    text-align: center;
    width: 280px;
}

.confirmationDialogButton {
    &.error {
        background: $lightRed;
    }
    &.success {
        background: $green;
    }
}
