.mainContainer {
    display: flex;
    height: 100%;

    .navigationBarWrapper {
        min-height: 100%;
    }

    .pageContentWrapper {
        min-height: 100%;
        width: 100%;
        overflow-y: auto;
    }
}
