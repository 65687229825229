.adornmentIconContainer {
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;

    &.defaultCursor {
        :first-child {
            cursor: default;
        }
    }

    &.pointerCursor {
        :first-child {
            cursor: pointer;
        }
    }
}
