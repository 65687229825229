@import 'styles/mixins.scss';

.tableWrapper {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
}

table[data-table-layout="fixed"] {
    table-layout: fixed;

    & > tbody > tr > td {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

tr {
    height: 43px;
}

td, th {
    text-align: left;
    padding: 0px 16px;
}

th {
    color: lightBlackColorWithOpacity(0.6);
    font-weight: unset;
}

tbody > tr:nth-child(odd) {
    background-color: grayColorWithOpacity(0.08);
}

.paginationText {
    color: lightBlackColorWithOpacity(0.6);
}
