@import 'styles/mixins.scss';

.studentStatusContainer {
    height: 35px;
    border-radius: 17px;
    text-align: center;
    padding: 2px 12px;

    &.active {
        background-color: greenColorWithOpacity(0.08);
    }

    &.inactive {
        background-color: lightRedColorWithOpacity(0.08);
    }
}
