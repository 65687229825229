@import "styles/colors.scss";

.notificationsCounterContainer {
    position: relative;
    height: 24px;

    .notificationsCounter {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        background-color: $violet;
        top: -5px;
        border-radius: 50%;
        left: 10px;
        padding: 2px;
        min-width: 20px;
    }
}

.navigationIcon {
    margin-right: 12px;
}
