@import 'styles/mixins.scss';

.cardItemContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 360px;
    border-radius: 10px;
    box-shadow: lightBlackColorWithOpacity(0.2) 0 0 5px;
    background-color: grayColorWithOpacity(0.08);
    cursor: pointer;
    margin: 16px;

    .imageContainer {
        width: 100%;
        object-fit: cover;
        border-radius: 10px 10px 0 0;
    }

    .descriptionContainer {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 100%;
        height: 50%;
        min-height: 70px;
        background-color: $white;
        border-radius: 0 0 10px 10px;
        padding: 16px;
    }
}
