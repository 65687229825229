@import 'styles/mixins.scss';

@mixin resultContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    height: 63px;
    width: 208px;
    border-radius: 8px;
}

.errorPointsContainer {
    @include resultContainer;
    border: 1px solid lightBlackColorWithOpacity(0.08);
    background-color: $white;
}

.resultContainer {
    @include resultContainer;
}
