@import 'styles/mixins.scss';

@mixin videoContainerStyle {
    height: 387px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    background-color: grayColorWithOpacity(0.08);
}

.videoContainer {
    position: relative;

    .playIconButton {
        position: absolute;
        cursor: pointer;
    }

    video {
        @include videoContainerStyle;

        &.hideVideoControls {
            &::-webkit-media-controls {
                display: none;
            }
        }

        &.showVideoControls {
            &::-webkit-media-controls {
                display: flex;
            }
        }
    }

    .noVideoPlaceholder {
        @include videoContainerStyle;
    }
}
