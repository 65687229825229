@import 'styles/mixins.scss';

.layoutContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $white;

    .headerContainer {
        width: 100%;
        padding: 0px 150px;
    }

    .formContentContainer {
        display: flex;
        justify-content: center;
        flex: 1;
        padding: 24px 40px 40px 40px;
        width: 100%;
    }
}
