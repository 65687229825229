@import 'styles/mixins.scss';

.questionsInfoCardContainer {
    height: 212px;
    width: 208px;
    border-radius: 12px;
    background-color: $white;
    box-shadow: 0 10px 50px 0 blackColorWithOpacity(0.2);
    padding: 16px;
}
