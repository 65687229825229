@import 'styles/mixins.scss';

.difficultLessonsCardContainer {
    height: 160px;
    width: 33.3%;
    max-width: 448px;
    border-radius: 8px;
    border: 1px solid grayColorWithOpacity(0.12);
    background-color: $white;
    padding: 15px;
    overflow: hidden;

    .lessonItem:not(:last-child) {
        padding-bottom: 6px;
        border-bottom: 1px solid grayColorWithOpacity(0.12);
    }
}
