@import 'styles/mixins.scss';

.overallProgressItemContainer {
    height: auto;
    width: 100%;
    border: 1px solid lightBlackColorWithOpacity(0.08);
    border-radius: 8px;
    background-color: $white;
    padding: 16px;

    .studentProgressBar {
        height: 8px;
        border-radius: 4px;
        margin-top: 8px;
        margin-bottom: 8px;
    }
}
