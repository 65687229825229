@import 'styles/colors.scss';

.lessonDescriptionContainer {
    width: 100%;
    margin-top: 8px;
}

.seeMoreLessButton {
    font-weight: 600;
    width: fit-content;
}
