@import 'styles/mixins.scss';

.snackbarRoot {
    top: 16px !important;
    z-index: 3000 !important;
}

.alertRoot {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 35px;
    width: 290px;
    border-radius: 22px !important;
}

.alertFilledSuccess {
    background-color: $green !important;
}

.alertFilledError {
    background-color: $red !important;
}
