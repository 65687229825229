@import 'styles/mixins.scss';

.paginationUl {
    li > button {
        border-radius: 8px !important;
        border: 1px solid grayColorWithOpacity(0.12) !important;
    }
}

.paginationItemSelected {
    background-color: $violet !important;
    color: $white !important;
}
