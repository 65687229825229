@import 'styles/mixins.scss';

.pageContainer {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .headerContainer {
        height: 48px;
        width: 100%;
        padding: 12px 24px;
        background-color: $lightGray;

        .searchContainer {
            display: flex;
            align-items: center;
            gap: 12px;

            .searchInput {
                background-color: transparent;
                border: none;
                outline: none;
                color: lightBlackColorWithOpacity(0.87);

                &::placeholder {
                    color: lightBlackColorWithOpacity(0.38);
                }
            }
        }
    }

    .contentContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 16px 24px;
        max-width: 1664px; // 1920 - 256(width of side NavigationBar)
    }
}
