@import 'styles/mixins.scss';

.searchResultsWrapper {
    position: relative;

    .searchResults {
        position: absolute;
        max-height: 253px;
        width: 100%;
        background-color: $white;
        padding: 16px 24px;
        z-index: 2000;
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow: auto;
        border-top: 1px solid $lightGray;

        .searchResultsItem {
            display: flex;
            align-items: center;
            min-height: 63px;
            max-height: 63px;
            width: 100%;
            border: 1px solid lightBlackColorWithOpacity(0.08);
            border-radius: 8px;
            background-color: $white;
            padding: 12px 16px;
        }
    }

    .overlay {
        position: fixed;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 1000;
        background-color: blackColorWithOpacity(0.38);
    }
}
