@import 'styles/mixins.scss';

.cardWrapper {
    height: 140px;
    width: 33.3%;
    max-width: 288px;
    margin: 16px;
}

.cardContainer {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid grayColorWithOpacity(0.12);
    padding: 16px;
    // overflow: hidden;
    background-color: $white;
    overflow-x: auto;
    overflow-y: auto;
}
