@import 'styles/mixins.scss';

.simulatorProgressItemContainer {
    height: auto;
    width: 100%;
    border: 1px solid grayColorWithOpacity(0.12);
    border-radius: 8px;
    background-color: $white;
    padding: 12px 16px;
}
