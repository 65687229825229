@import 'styles/colors.scss';

.statusDot {
    border-radius: 50%;

    &.active {
        background-color: $green;
    }

    &.inactive {
        background-color: $lightRed;
    }

    &.pending {
        background-color: $orange;
    }

    &.ready {
        background-color: $lightBlue;
    }

    &.passed {
        background-color: $violet;
    }
}
